import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import qs from 'query-string';
import {
  API_URL,
  CLIENT_ID, CLIENT_SECRET, PERFORM_URL, REDIRECT_URI, SCOPE, SSO_URL,
} from './config';
import { getToken } from './jwt.service';

export const SsoService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.interceptors.request.use(
      (config) => {
        const token = getToken();
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
    Vue.axios.interceptors.response.use(undefined, (err) => {
      const { config, message } = err;
      if (!config || !config.retry) {
        return Promise.reject(err);
      }
      // retry while Network timeout or Network Error
      if (!(message.includes('status code 500') || message.includes('timeout') || message.includes('Network Error'))) {
        return Promise.reject(err);
      }
      config.retry -= 1;
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => Vue.axios(config));
    });
  },
  getIdToken(code) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return Vue.axios.get(`${SSO_URL}/oauth/token?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&response_type=token&scope=${SCOPE}&redirect_uri=${REDIRECT_URI}&code=${code}&grant_type=authorization_code`, config)
      .catch((error) => {
        throw new Error(`[RWV] SsoService ${error}`);
      });
  },
  loginForm(params) {
    const data = params;
    data.client_id = CLIENT_ID;
    data.client_secret = CLIENT_SECRET;
    data.grant_type = 'password';
    return Vue.axios.post('/api/front/v1/public/login', new URLSearchParams(data).toString());
  },
  verifyToken(idToken) {
    const requestBody = {
      id_token: idToken,
      renew: 1,
    };
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return Vue.axios.post(`${SSO_URL}/api/verify/token`, qs.stringify(requestBody), config).catch((error) => {
      throw new Error(`[RWV] SsoService ${error}`);
    });
  },
  updatePassword(password, newPassword) {
    const requestBody = {
      token: getToken(),
      password,
      new_password: newPassword,
      confirm_password: newPassword,
    };
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return Vue.axios.put(`${SSO_URL}/api/oidc/password`, qs.stringify(requestBody), config);
  },
  updateLocale(locale) {
    const requestBody = {
      locale,
    };
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return Vue.axios.post(`${PERFORM_URL}/api/front/account/locale`, qs.stringify(requestBody), config);
  },
};

const ApiService = {
  query(resource, params, retry = 3) {
    return Vue.axios.get(`/api/${resource}`, {
      retry,
      params,
    });
  },

  downloadQuery(resource, params) {
    return Vue.axios.get(`/api/${resource}`, {
      params,
      responseType: 'blob',
    });
  },

  get(resource) {
    return Vue.axios.get(`/api/${resource}`);
  },

  post(resource, params, retry = 3) {
    return Vue.axios.post(`/api/${resource}`, params, { retry });
  },

  postFile(resource, params) {
    return Vue.axios.post(`/api/${resource}`, params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  },

  put(resource, params) {
    return Vue.axios.put(`/api/${resource}`, params);
  },

  delete(resource, params) {
    return Vue.axios.delete(`/api/${resource}`, {
      params,
    });
  },
};

export default ApiService;
