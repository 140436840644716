import ApiService, { SsoService } from '@/common/api.service';
import {
  destroyToken, getToken, saveToken, saveLocale,
} from '@/common/jwt.service';
import { ROLE_PLAYER } from '@/common/userRoles';

import {
  SSO_URL, CLIENT_ID, REDIRECT_URI, SCOPE,
} from '@/common/config';
import {
  LOGIN,
  LOGINFORM,
  UPDATE_PASSWORD,
  UPDATE_LOCALE,
  CUSTOM_AUTH_ACTION,
  LOGOUT,
  CHECK_AUTH,
  CALLBACK,
} from './actions.type';

import {
  SET_AUTH,
  PURGE_AUTH,
  SET_ERROR,
  FETCH_START,
  FETCH_END,
  SET_PORTAL,
  SET_LOGIN,
  SET_PASSWORD,
  SET_REFERER,
} from './mutations.type';

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!getToken(),
  locale: 'en',
  isLoading: false,
  portal: null,
  login: null,
  password: null,
  domain: null,
  referer: null,
};

const getters = {
  currentUser() {
    return state.user;
  },
  userAvatar() {
    return state.user.avatarURL ? `${SSO_URL}/api/avatar?hash=${state.user.avatarURL}` : '';
  },
  isAuthenticated() {
    return state.isAuthenticated;
  },
  isLoading() {
    return state.isLoading;
  },
  isAuthorized() {
    return state.user.roles
      && (state.user.roles.includes(ROLE_PLAYER));
  },
  isAdmin() {
    return state.user.roles && state.user.roles.includes(ROLE_PLAYER);
  },
  locale() {
    return state.user ? state.user.language.toLowerCase() : 'fr';
  },
  portal() {
    return state.portal;
  },
  defaultLogin() {
    return state.login;
  },
  defaultPassword() {
    return state.password;
  },
  referer() {
    return state.referer;
  },
  domain() {
    return state.domain;
  },
};

const actions = {
  // eslint-disable-next-line consistent-return
  [LOGIN]() {
    let origin = 'vts_player_web';
    if (state.portal != null) {
      origin = state.portal;
    }
    let login = '';
    if (state.login != null) {
      login = `&login=${btoa(state.login)}`;
    }
    window.location.href = `${SSO_URL}/authorize?client_id=${CLIENT_ID}&response_type=code&scope=${SCOPE}&redirect_uri=${REDIRECT_URI}&_origin=${origin}${login}`;
  },
  [CALLBACK](context, { code }) {
    context.commit(FETCH_START);
    return new Promise((resolve, reject) => {
      SsoService.getIdToken(code).then(({ data }) => {
        context.commit(SET_AUTH, { id_token: data.id_token });
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [CUSTOM_AUTH_ACTION](context, params = {}) {
    return new Promise((resolve, reject) => {
      if (params.method === 'get') {
        ApiService.query(`front/v1/public/${params.path}`, params.data, 1)
          .then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
      } else {
        ApiService.post(`front/v1/public/${params.path}`, params.data, 1)
          .then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
      }
    });
  },
  [LOGINFORM](context, params = {}) {
    context.commit(FETCH_START);
    return new Promise((resolve, reject) => {
      SsoService.loginForm(params)
        .then(({ data }) => {
          const token = data.data.id_token;
          SsoService.verifyToken(token)
            .then((response) => {
              const { claims } = response.data;
              context.commit(SET_AUTH, { ...claims, finishIsLoading: true });
              resolve();
            });
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(FETCH_START);
    context.commit(PURGE_AUTH);
    window.location.href = `${SSO_URL}/logout`;
  },
  [CHECK_AUTH](context) {
    context.commit(FETCH_START);
    // Token exist => verify token
    const token = getToken();
    if (token) {
      return new Promise((resolve, reject) => {
        SsoService.verifyToken(token)
          .then(({ data }) => {
            const { claims } = data;
            if (state.login && claims.login !== state.login) {
              context.commit(PURGE_AUTH);
              reject();
            }
            context.commit(SET_AUTH, { ...claims, finishIsLoading: true });
            resolve();
          })
          .catch(() => {
            context.commit(PURGE_AUTH);
            reject();
          });
      });
    }
    context.commit(PURGE_AUTH);
    return false;
  },
  [UPDATE_PASSWORD](_, { password, newPassword }) {
    return SsoService.updatePassword(password, newPassword);
  },
  [UPDATE_LOCALE](_, params) {
    return ApiService.post('front/account/locale', params);
  },
};

const mutations = {
  [FETCH_START]() {
    state.isLoading = true;
  },
  [FETCH_END]() {
    state.isLoading = false;
  },
  // eslint-disable-next-line no-shadow
  [SET_ERROR](states, error) {
    states.errors = error;
  },
  // eslint-disable-next-line no-shadow
  async [SET_AUTH](states, user, finishIsLoading = false) {
    states.isAuthenticated = true;
    states.user = user;
    states.errors = {};
    state.locale = user ? user.language && user.language.toLowerCase() : 'en';
    saveLocale(state.locale);

    if (states.user.login) {
      states.user.initials = states.user.firstName[0] + states.user.lastName[0];
    }

    saveToken(states.user.id_token);

    if (finishIsLoading) {
      states.isLoading = false;
    }
  },
  [PURGE_AUTH]() {
    // eslint-disable-next-line no-undef
    destroyToken();
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
  },
  [SET_PORTAL](states, portal) {
    state.portal = portal;
  },
  [SET_LOGIN](states, login) {
    const base64regex = new RegExp('^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})([=]{1,2})?$');
    state.login = base64regex.test(login) ? atob(login) : login;
  },
  [SET_PASSWORD](states, pass) {
    const base64regex = new RegExp('^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})([=]{1,2})?$');
    state.password = base64regex.test(pass) ? atob(pass) : pass;
  },
  [SET_REFERER](states, referer) {
    state.referer = referer;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
